import {Injectable, signal, WritableSignal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlockUiService {
  public loading:WritableSignal<boolean> = signal<boolean>(false)
  public activateBlockUI:WritableSignal<boolean> = signal<boolean>(true)

  constructor() { }

  public start(): void {
    this.loading.set(true);
  }

  public stop(): void {
    this.loading.set(false);
  }
}
